.leftSideMenuContainer {
  padding: 0px !important;
  background-color: #ffffff;
  border-top: 1px solid #eaedf3;
  max-width: 370px;
  min-width: 370px;
  width: 370px;
}

.leftSideMenu {
  padding: 36px 16px;
}

.formattedBody {
  white-space: pre;
  border: 1px solid #d6dce8;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  width: 100%;
  background: white;
  padding: 10px 15px;
  transition: 0.2s all;
  outline: none;
  font-size: 0.875rem;
  color: #6d6d6d;
}

.leftSideMenuItem {
  color: #344054;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  vertical-align: middle;
}

.leftSideMenuItem.selected {
  border-color: #f0f9ff;
  background-color: #f0f9ff;
  color: #026aa2;
}

.leftSideMenuHeader {
  color: #393d50;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 40px;
}

.leftSideMenuItem a {
  color: #3e3f42 !important;
  text-decoration: none;
}

.newCategoryFormCol {
  float: left;
  width: 50%;
}

.formContainer {
  display: block;
}

.customInputContainer {
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 7px;
}

.columnTwo {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;
  padding-top: 7px;
}

.customInputLabel {
  color: #9b9b9b;
  display: block;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-transform: uppercase;
}

.statusDescription {
  left: 0px;
  right: 0px;
  top: 56px;
  bottom: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #6d7382;
}

.inputFieldContainer {
  margin-bottom: 20px;
}

.spaceCol {
  float: left;
  width: 2%;
}

.categoryTypeSelection {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #393d50;
}

.categoryType {
  cursor: pointer;
  display: block;
  float: left;
  margin-right: 5px;
  padding: 10px;
  width: 135px;
}

.categoryType.selected {
  background-color: #e6f2f3;
}

.editableFieldsActive {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  position: relative;
  top: -5px;
}

.uploaderBody {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .contentLeft {
    display: flex;
    align-items: center;
  }
}

.mainContentUploader {
  background: #f5f6f9;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
  margin-top: 9px;

  &Error {
    border-style: solid;
    border-color: #e67670;
  }
}

.uploaderIcon {
  color: #9ea0a5;
  display: inline-block;
  line-height: 30px;
  margin: 10px 10px 0;
  float: left;
}
.uploaderPlaceholder {
  color: #9ea0a5;
  font-size: 14px;
  line-height: 45px;
}

.newCategoryFormColTwo {
  display: flex;
  flex-direction: column;
}

.bottomInput {
  margin-top: 20px;
}

.image {
  height: 150px;
  width: 250px;
}

.label {
  margin-bottom: 9px;
}

.imageContainer {
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #f5f6f9;
  height: 150px;
  width: 250px;
  padding: 30px;
  margin-top: 10px;
  position: relative;
  border-radius: 4px;
}

.close {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.datePickerContainer {
  position: relative;
}
